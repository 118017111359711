.awssld {
  --slider-height-percentage: 100%;
  --slider-transition-duration: 450ms;
  --organic-arrow-thickness: 1px;
  --organic-arrow-border-radius: 0px;
  --organic-arrow-height: 40px;
  --organic-arrow-color: #cf3438;
  --control-button-width: 8%;
  --control-button-height: 29%;
  --control-button-background: #222222;
  --control-bullet-color: #cecece;
  --control-bullet-active-color: #222222;
  --content-background-color: none;
  --loader-bar-color: #851515;
  --loader-bar-height: 1px;
}

.awssld__bullets {
  bottom: -30px;
}

.awssld__bullets button {
  width: 8px;
  height: 8px;
  margin: 4px;
  pointer-events: none;
}

.awssld__bullets .awssld__bullets--active {
  transform: none !important;
}

.awssld__bullets .awssld__bullets--loading {
  transform: none !important;
}

.awssld__controls {
  display: none;
}

.awssld__content > img {
  margin-top: 0 !important;
  padding: 0 !important;
}

#main .awssld__container {
  padding-bottom: var(--slider-height-percentage) !important;
}

#main .awssld__wrapper {
  overflow: hidden;
  height: auto;
}

#main .awssld__box {
  left: 20px;
  height: auto;
}

#main .awssld__bullets {
  bottom: 8px;
}

#main .awssld__content {
  align-items: initial;
  justify-content: initial;
  border-radius: 6px;
  width: calc(100% - 40px);
  padding-bottom: calc(100% - 40px);
  position: absolute;
}

#main .awssld__content > div > p {
  padding-top: 26px;
  padding-left: 24px;
}

#main .awssld__content > div > h1 {
  padding-left: 24px;
}

#main .awssld__content > img {
  margin-top: 0 !important;
  padding: 0 !important;
  height: initial !important;
  bottom: 0 !important;
  top: unset !important;
}

#main .aws-sld__content p {
  transform: translate3d(0, 0, 0);
  opacity: 1;
  transition: transform 0.45s cubic-bezier(0.15, 0.3, 0.15, 1),
    opacity 0.35s ease-out;
}

#main .aws-sld__content p:nth-child(2) {
  transition-delay: 0.05s, 0.05s;
}

#main .aws-sld__content--exit p {
  transition: transform 0.225s cubic-bezier(0.85, 0, 0.85, 0.7),
    opacity 0.4s ease-out;
}

#main .aws-sld__content--exit p:nth-child(2) {
  transition-delay: 0.05s, 0.05s;
}

#main .aws-sld__content--moveLeft p {
  transform: translate3d(-50px, 0, 0);
  opacity: 0;
}

#main .aws-sld__content--moveRight p {
  transform: translate3d(50px, 0, 0);
  opacity: 0;
}
